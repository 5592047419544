import * as React from 'react';
import { FeatureFlagDefinitionData } from "hooks/feature-flag-definition/use-feature-flag-definitions.types";
import { getBundle, ILocaleProps } from "../../lib/locales";
import { FeatureFlagInformationItem } from './feature-flag-definition-detail-item';
import { FeatureFlagReleaseVersion, FeatureFlagVisibility } from "../../hooks/common/common.types";

export interface FeatureFlagConfirmDialogProps extends ILocaleProps {
  flagDefinition: FeatureFlagDefinitionData;
  defaultValue: string;
  clearFlagValues: boolean;
  visibility?: FeatureFlagVisibility;
  releaseVersion?: FeatureFlagReleaseVersion | null;
  overrideOptIn: boolean;
}

export const FeatureFlagConfirmDialog: React.FC<FeatureFlagConfirmDialogProps> = (props) => {
  const { flagDefinition, defaultValue, clearFlagValues, visibility,
    releaseVersion, overrideOptIn, locale } = props;
  const bundle = getBundle(locale);

  let newDefaultValue;
  if (flagDefinition.possibleValues.type.toLowerCase() === "list") {
    newDefaultValue = defaultValue;
  } else {
    newDefaultValue = defaultValue === "true" ? bundle.on : bundle.off;
  }

  const newClearFlagValues = clearFlagValues ? bundle.yes : bundle.no;

  let newClientAdminPermission = bundle.none;
  if (visibility?.visible) {
    newClientAdminPermission = bundle.canEdit;
  } else if (visibility?.criteria?.releaseVersion) {
    const v = visibility?.criteria.releaseVersion.version;
    newClientAdminPermission = `${bundle.releaseVersion} - ${visibility?.criteria.releaseVersion.productType} ${v.major}.${v.minor}.${v.patch}`;
  }

  let newReleaseVersion = bundle.none;
  if (releaseVersion) {
    const v = releaseVersion.version;
    newReleaseVersion = `${releaseVersion.productType} ${v.major}.${v.minor}.${v.patch}`;
  }

  const newReleaseVersionItem = flagDefinition.possibleValues.type.toLowerCase() === "boolean" && (
    <FeatureFlagInformationItem
      title={bundle.globalEnablementByReleaseVersion}
      value={newReleaseVersion}
      tooltip={true}
      tooltipTitle={bundle.flagDefinitionEnablementByReleaseVersionDescription}
      testId="global-release-version"
    />
  );

  const newOverrideOptIn = overrideOptIn ? bundle.yes : bundle.no;

  return (
    <>
      <FeatureFlagInformationItem
        title={bundle.globalStatus}
        value={newDefaultValue}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionGlobalStatusDescription}
        testId="global-settings-status"
      />
      <FeatureFlagInformationItem
        title={bundle.flagDefinitionEditDialogueOverrideLabel}
        value={newClearFlagValues}
        tooltip={true}
        tooltipTitle={bundle.featureFlagDefEditDialogueOverrideNote}
        testId="global-clear-flag-values"
      />
      <FeatureFlagInformationItem
        title={bundle.globalPermissions}
        value={newClientAdminPermission}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionClientPermissionsDescription}
        testId="global-permissions"
      />
      {newReleaseVersionItem}
      <FeatureFlagInformationItem
        title={bundle.flagDefinitionEditDialogueOverrideOptIn}
        value={newOverrideOptIn}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionEditDialogueOverrideOptInWarning}
        testId="global-override-opt-in"
      />
    </>
  );
};

export default FeatureFlagConfirmDialog;
