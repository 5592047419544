import axios from 'axios';

import { useCallback, useEffect, useState } from 'react';
import { FailedRequest, SucceededRequest } from '../common/common.types';
import { fetchPagedResults } from '../common/http';
import {
  FeatureFlagScheduleTask,
  FlagDefinitionChange,
  HashFeatureFlagScheduleTasks,
  PutFlagValueForBulkTenants,
  UseFeatureFlagScheduleTaskProps,
  UseFeatureFlagScheduleTaskResults,
} from './use-feature-flag-schedule-task.types';

/**
 * React hook to fetch all feature flag schedule tasks
 */
export function useFeatureFlagScheduleTasks(props: UseFeatureFlagScheduleTaskProps): UseFeatureFlagScheduleTaskResults {
  const { apiBaseUrl, accessToken, flagKey, tenantGroup } = props;
  const [scheduleTasks, setScheduleTasks] = useState([] as FeatureFlagScheduleTask[]);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [succeededRequests, setSucceededRequests] = useState<SucceededRequest[]>([]);
  const [failedRequests, setFailedRequests] = useState<FailedRequest[]>([]);
  const [loadingRequests, setLoadingRequests] = useState<number>(0);

  const getFeatureFlagScheduleTask = useCallback((tenantGroupId) => scheduleTasks!.find((task) => task.tenantGroupId === tenantGroupId), [scheduleTasks]);

  const getHashFeatureFlagScheduleTasks = useCallback(() => {
    const hashScheduleTasks: HashFeatureFlagScheduleTasks = {};
    scheduleTasks!.forEach(task => hashScheduleTasks[task.tenantGroupId] = task);
    return hashScheduleTasks;
  }, [scheduleTasks]);

  const getFeatureFlagScheduleTasks = useCallback(async () => {
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/${flagKey}/scheduledTasks/${tenantGroup}`;
    try {
      setLoading(true);
      const tasks = await fetchPagedResults<FeatureFlagScheduleTask>(apiPath, accessToken);
      if (tasks.length) {
        setScheduleTasks(tasks);
      }
    } catch (err) {
      setError(err as any);
    } finally {
      setLoading(false);
    }
  }, [accessToken, apiBaseUrl, flagKey, tenantGroup]);

  const createFeatureFlagScheduleTask = useCallback((
    tenantGroupId: string,
    region: string,
    scheduleDate: string,
    flagDefinitionChange?: FlagDefinitionChange,
    flagValueChange?: PutFlagValueForBulkTenants,
  ) => {
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/scheduledTasks/${tenantGroup}`;
    setLoadingRequests(reqs => reqs + 1);

    const authConfig = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const body: any = {
      flagKey,
      tenantGroupId,
      region,
      scheduleDate,
    };
    if (flagDefinitionChange !== undefined) {
      body.flagDefinitionChange = flagDefinitionChange;
    }
    if (flagValueChange !== undefined) {
      body.flagValueChange = flagValueChange;
    }

    axios.post(apiPath, body, authConfig)
      .then((response) => {
        setSucceededRequests(reqs => [
          ...reqs,
          { response },
        ]);
        setScheduleTasks(tasks => {
          tasks.push(response.data);
          return tasks;
        });
      })
      .catch((error) => {
        setFailedRequests(reqs => [
          ...reqs,
          { response: null, error },
        ]);
      })
      .finally(() => {
        setLoadingRequests(reqs => reqs - 1);
      });
  }, [accessToken, apiBaseUrl, flagKey, tenantGroup]);

  const updateFeatureFlagScheduleTask = useCallback((
    tenantGroupId: string,
    region: string,
    scheduleDate: string,
    flagDefinitionChange?: FlagDefinitionChange,
    flagValueChange?: PutFlagValueForBulkTenants,
  ) => {
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/${flagKey}/scheduledTasks/${tenantGroup}/${tenantGroupId}`;
    setLoadingRequests(reqs => reqs + 1);

    const authConfig = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const body: any = {
      region,
      scheduleDate,
    };
    if (flagDefinitionChange !== undefined) {
      body.flagDefinitionChange = flagDefinitionChange;
    }
    if (flagValueChange !== undefined) {
      body.flagValueChange = flagValueChange;
    }

    axios.patch(apiPath, body, authConfig)
      .then((response) => {
        setSucceededRequests(reqs => [
          ...reqs,
          { response },
        ]);
        setScheduleTasks(tasks => tasks.map(task => {
          if (task.tenantGroupId !== tenantGroupId) {
            return task;
          }
          return response.data;
        }));
      })
      .catch((error) => {
        setFailedRequests(reqs => [
          ...reqs,
          { response: null, error },
        ]);
      })
      .finally(() => {
        setLoadingRequests(reqs => reqs - 1);
      });
  }, [accessToken, apiBaseUrl, flagKey, tenantGroup]);

  const deleteFeatureFlagScheduleTask = useCallback((
    tenantGroupId: string,
  ) => {
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/${flagKey}/scheduledTasks/${tenantGroup}/${tenantGroupId}`;
    setLoadingRequests(reqs => reqs + 1);

    const authConfig = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    axios.delete(apiPath, authConfig)
      .then((response) => {
        setSucceededRequests(reqs => [
          ...reqs,
          { response },
        ]);
        setScheduleTasks(tasks => tasks.filter(task => task.tenantGroupId !== tenantGroupId));
      })
      .catch((error) => {
        setFailedRequests(reqs => [
          ...reqs,
          { response: null, error },
        ]);
      })
      .finally(() => {
        setLoadingRequests(reqs => reqs - 1);
      });
  }, [accessToken, apiBaseUrl, flagKey, tenantGroup]);

  const clearSucceededRequests = useCallback(
    () => setSucceededRequests([]),
    [],
  );

  const clearFailedRequests = useCallback(
    () => setFailedRequests([]),
    [],
  );

  useEffect(() => {
    getFeatureFlagScheduleTasks();
  }, [accessToken, apiBaseUrl, getFeatureFlagScheduleTasks]);

  return {
    scheduleTasks,
    error,
    loading,
    getFeatureFlagScheduleTask,
    getHashFeatureFlagScheduleTasks,
    createFeatureFlagScheduleTask,
    updateFeatureFlagScheduleTask,
    deleteFeatureFlagScheduleTask,
    succeededRequests,
    clearSucceededRequests,
    failedRequests,
    clearFailedRequests,
    loadingRequests,
  };
}
