import * as React from "react";
import { makeStyles, createStyles, Theme } from "@bb-ui/react-library";
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Checkbox } from '@bb-ui/react-library/dist/components/Checkbox';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  checkBox: {
    display: "flex",
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
}));

export interface IFeatureFlagCheckBoxProps {
  checked: boolean;
  onChange: (event: any) => void;
  label: string;
  disabled?: boolean;
}

export const FeatureFlagCheckBox: React.FunctionComponent<IFeatureFlagCheckBoxProps> =
  (props) => {
    const classes = useStyles(props);
    const { checked, onChange, label, disabled } = props;
    return (
      <div className={classes.checkBox}>
        <Checkbox data-testid="feature-flag-checkbox" checked={checked} disabled={disabled ?? false} onChange={onChange} />
        <Typography variant="h5"> { label } </Typography>
      </div>
    );
  };

export default FeatureFlagCheckBox;
