import * as React from 'react';
import {
  createStyles,
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  PrimaryButton,
  Radio,
  RadioGroup,
  Theme,
} from '@bb-ui/react-library';
import { FieldsetProps, LegendProps } from "@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types";
import {
  FeatureFlagProductVersion,
  FeatureFlagReleaseVersion,
  FeatureFlagVisibility,
} from "hooks/common/common.types";
import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
} from "hooks/feature-flag-definition/use-feature-flag-definitions.types";
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { getBundle, ILocaleProps } from "../../lib/locales";
import FeatureFlagDefinitionReleaseVersion from "./feature-flag-definition-release-version";
import FeatureFlagCheckBox from "./feature-flag-definition-check-box";
import FeatureFlagConfirmDialog from "./feature-flag-definition-confirm-dialog";
import { useState } from "react";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogTitle: {
    alignItems: 'start',
  },
  dialogContent: {
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  section: {
    marginTop: theme.spacing(2),
  },
  sectionDescription: {
    marginLeft: theme.spacing(3.25),
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  sectionWarning: {
    marginBottom: theme.spacing(1),
    color: '#e86826',
  },
  overrideOptIn: {
    marginTop: theme.spacing(1),
  },
  disableFlagValue: {
    marginTop: theme.spacing(1),
  },
  radioButton: {
    marginLeft: theme.spacing(-1),
  },
  inlineNote: {
    marginTop: theme.spacing(0.75),
  },
}));

export interface FeatureFlagEditDialogProps extends ILocaleProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (flagKey: string, defaultValue?: string, clearFlagValues?: boolean,
    visibility?: FeatureFlagVisibility, releaseVersion?: FeatureFlagReleaseVersion | null, overrideOptIn?: boolean | null) => void;
}

export const FeatureFlagEditDialog: React.FC<FeatureFlagEditDialogProps> = (props) => {
  const classes = useStyles(props);
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const { flagDefinition, isDialogOpen, dialogToggle, updateFeatureFlagDefinition, locale } = props;
  const bundle = getBundle(locale);

  const NO_PRODUCT_TYPE = 'None';
  const NO_PRODUCT_VERSION = '';
  const NO_ERROR = '';

  function formatReleaseVersionNumber(releaseVersion?: FeatureFlagReleaseVersion) {
    if (!releaseVersion) {
      return NO_PRODUCT_VERSION;
    }
    const v = releaseVersion.version;
    return `${v.major}.${v.minor}.${v.patch}`;
  }

  function parseReleaseVersionNumber(version?: string): FeatureFlagProductVersion | undefined {
    if (!version) {
      return;
    }

    const match = version.match(/^(\d+)\.(\d+)\.(\d+)$/);
    if (!match) {
      throw new Error("Invalid version number");
    }

    const [, major, minor, patch] = match.map(Number);
    return { major, minor, patch };
  }

  function validateReleaseVersion(productType: string, productVersion: string): [string, FeatureFlagReleaseVersion | null] {
    let error = NO_ERROR;
    let releaseVersion: FeatureFlagReleaseVersion | null = null;

    if (productType !== NO_PRODUCT_TYPE) {
      try {
        const versionNumber = parseReleaseVersionNumber(productVersion);
        if (!versionNumber) {
          error = bundle.flagDefinitionEditDialogueProductVersionRequired;
        } else {
          releaseVersion = {
            productType,
            version: versionNumber,
          };
        }
      } catch {
        error = bundle.flagDefinitionEditDialogueProductVersionInvalid;
      }
    }

    return [error, releaseVersion];
  }

  function validateProductType(adminPermission: string, productType: string): [string, boolean] {
    let error = NO_ERROR;
    let productTypeSet = false;

    if (adminPermission === "version") {
      if (productType === NO_PRODUCT_TYPE) {
        error = bundle.flagDefinitionEditDialogueProductTypeRequired;
      } else {
        productTypeSet = true;
      }
    }

    return [error, productTypeSet];
  }

  const initialDefaultValue = flagDefinition.defaultValue;
  const initialClientAdminPermission = () => {
    if (flagDefinition.visibility.visible) {
      return "edit";
    }
    if (flagDefinition.visibility.criteria?.releaseVersion) {
      return "version";
    }
    return "none";
  };
  const initialOverrideOptIn = () => {
    if (flagDefinition.overrideOptIn) {
      return "enabled";
    }
    return "disabled";
  };

  const initialClearFlagValues = "disabled";
  const [flagNewDefaultValue, setFlagNewDefaultValue] = React.useState<string>(initialDefaultValue);
  const [clientAdminPermission, setClientAdminPermission] = React.useState<string>(initialClientAdminPermission);
  const [clearFlagValues, setClearFlagValues] = React.useState<string>(initialClearFlagValues);

  const initialVisibilityReleaseProductType = flagDefinition.visibility.criteria?.releaseVersion?.productType || NO_PRODUCT_TYPE;
  const [initialVisibilityReleaseProductTypeError] = validateProductType(clientAdminPermission, initialVisibilityReleaseProductType);
  const initialVisibilityReleaseProductVersion = formatReleaseVersionNumber(flagDefinition.visibility.criteria?.releaseVersion);
  const [initialVisibilityReleaseProductVersionError] = validateReleaseVersion(initialVisibilityReleaseProductType, initialVisibilityReleaseProductVersion);
  const [visibilityReleaseProductType, setVisibilityReleaseProductType] = React.useState<string>(initialVisibilityReleaseProductType);
  const [visibilityReleaseProductTypeError, setVisibilityReleaseProductTypeError] = React.useState<string>(initialVisibilityReleaseProductTypeError);
  const [visibilityReleaseProductVersion, setVisibilityReleaseProductVersion] = React.useState<string>(initialVisibilityReleaseProductVersion);
  const [visibilityReleaseProductVersionError, setVisibilityReleaseProductVersionError] = React.useState<string>(initialVisibilityReleaseProductVersionError);
  const [visibilityPayload, setVisibilityPayload] = React.useState<FeatureFlagVisibility>();
  const [releaseVersionPayload, setReleaseVersionPayload] = React.useState<FeatureFlagReleaseVersion | null>(null);

  const initialReleaseProductType = flagDefinition.releaseVersion?.productType || NO_PRODUCT_TYPE;
  const initialReleaseProductVersion = formatReleaseVersionNumber(flagDefinition.releaseVersion);
  const [initialReleaseProductVersionError] = validateReleaseVersion(initialReleaseProductType, initialReleaseProductVersion);
  const [releaseProductType, setReleaseProductType] = React.useState<string>(initialReleaseProductType);
  const [releaseProductVersion, setReleaseProductVersion] = React.useState<string>(initialReleaseProductVersion);
  const [releaseProductVersionError, setReleaseProductVersionError] = React.useState<string>(initialReleaseProductVersionError);

  const [overrideOptIn, setOverrideOptIn] = React.useState<string>(initialOverrideOptIn);
  const { possibleValues } = flagDefinition;

  const [isEditDialogMode, toggleEditDialogMode] = React.useState(true);
  const [isChecked, setIsChecked] = useState(false);

  function handleOnClose() {
    dialogToggle(false);

    // Reset defaults in case dialog is opened again
    setFlagNewDefaultValue(initialDefaultValue);
    setClientAdminPermission(initialClientAdminPermission);
    setClearFlagValues(initialClearFlagValues);
    setReleaseProductType(initialReleaseProductType);
    setReleaseProductVersion(initialReleaseProductVersion);
    setReleaseProductVersionError(initialReleaseProductVersionError);
    setVisibilityReleaseProductType(initialVisibilityReleaseProductType);
    setVisibilityReleaseProductTypeError(initialVisibilityReleaseProductTypeError);
    setVisibilityReleaseProductVersion(initialVisibilityReleaseProductVersion);
    setVisibilityReleaseProductVersionError(initialVisibilityReleaseProductVersionError);
    setOverrideOptIn(initialOverrideOptIn);
    setVisibilityPayload(undefined);
    setReleaseVersionPayload(null);

    toggleEditDialogMode(true);
    setIsChecked(false);
  }

  function handleDefaultValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFlagNewDefaultValue(event.target.value);
  }

  function handleClientAdminPermissionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClientAdminPermission(event.target.value);
  }

  function handleClearFlagValuesChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClearFlagValues(event.target.value);
  }

  function handleOverrideOptIn(event: React.ChangeEvent<HTMLInputElement>) {
    setOverrideOptIn(event.target.value);
  }

  function handleOnContinue() {
    const newVisibleSetting = clientAdminPermission === "edit";
    const visibility: FeatureFlagVisibility = {
      visible: newVisibleSetting,
    };
    // If global visibility isn't changing, preserve criteria.
    if (flagDefinition.visibility.criteria && flagDefinition.visibility.visible === newVisibleSetting) {
      visibility.criteria = flagDefinition.visibility.criteria;
    }

    // Validate visibility product type before allowing submit
    const [visibilityProductTypeError, visibilityProductTypeSet] = validateProductType(clientAdminPermission, visibilityReleaseProductType);
    setVisibilityReleaseProductTypeError(visibilityProductTypeError);
    if (visibilityProductTypeError) {
      return;
    }
    if (visibilityProductTypeSet) {
      // Validate visibility release version before allowing submit
      const [visibilityReleaseVersionError, visibilityReleaseVersion] = validateReleaseVersion(visibilityReleaseProductType, visibilityReleaseProductVersion);
      setVisibilityReleaseProductVersionError(visibilityReleaseVersionError);
      if (visibilityReleaseVersionError) {
        return;
      }
      if (visibilityReleaseVersion) {
        const tenants = visibility.criteria?.tenants ?? [];
        const fleets = visibility.criteria?.fleets ?? [];
        const regions = visibility.criteria?.regions ?? [];
        const releaseVersionCriteria: FeatureFlagReleaseVersion = {
          ...visibilityReleaseVersion,
        };
        visibility.criteria = {
          ...(tenants.length && { tenants }),
          ...(regions.length && { regions }),
          ...(fleets.length && { fleets }),
          ...(releaseVersionCriteria && { releaseVersion: releaseVersionCriteria }),
        };
      }
    // If no visibility product type is set, remove release version criteria
    } else if (visibility.criteria?.releaseVersion) {
      delete visibility.criteria.releaseVersion;
      // Remove criteria if no other criteria are set
      if (!Object.keys(visibility.criteria).length) {
        delete visibility.criteria;
      }
    }
    setVisibilityPayload(visibility);

    // Validate default value release version before allowing submit
    const [releaseVersionError, releaseVersion] = validateReleaseVersion(releaseProductType, releaseProductVersion);
    setReleaseProductVersionError(releaseVersionError);
    if (releaseVersionError) {
      return;
    }
    setReleaseVersionPayload(releaseVersion);

    toggleEditDialogMode(false);
  }

  function handleOnSubmit() {
    const clearValues = clearFlagValues === "enabled";
    const flagNewOverrideOptIn = overrideOptIn === "enabled";

    updateFeatureFlagDefinition(flagDefinition.flagKey, flagNewDefaultValue, clearValues, visibilityPayload,
      releaseVersionPayload, flagNewOverrideOptIn);
    dialogToggle(false);
  }

  function handleConfirm(event: React.ChangeEvent<HTMLInputElement>) {
    setIsChecked(event.target.checked);
  }

  const defaultValueOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleDefaultValueChange}
        value={flagNewDefaultValue}
        aria-label={bundle.flagDefinitionDefaultValueAriaLabel}
        name="default-value-selection"
      >
        {possibleValues.type.toLowerCase() === "list" &&
          possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => {
            const value = option.value.toLowerCase();
            return (
              <FormControlLabel className={classes.radioButton} key={value} value={value} control={<Radio />} label={value} />
            );
          })
        }
        {possibleValues.type.toLowerCase() !== "list" &&
          <>
            <FormControlLabel className={classes.radioButton} value="false" control={<Radio />} label={bundle.off} />
            <FormControlLabel className={classes.radioButton} value="true" control={<Radio />} label={bundle.on} />
          </>
        }
      </RadioGroup>
    </Fieldset>
  );

  const overrideValuesOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClearFlagValuesChange}
        value={clearFlagValues}
        aria-label={bundle.flagDefinitionOverrideAriaLabel}
        name="override-values-selection"
      >
        <FormControlLabel className={classes.radioButton} value="disabled" control={<Radio />} label={bundle.no} data-testid="flag-definition-edit-dialog-override-disable" />
        <FormControlLabel className={classes.radioButton} value="enabled" control={<Radio />} label={bundle.yes} data-testid="flag-definition-edit-dialog-override-enable" />
      </RadioGroup>
    </Fieldset>
  );

  const clientPermissionOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClientAdminPermissionChange}
        value={clientAdminPermission}
        aria-label={bundle.flagDefinitionClientPermissionsAriaLabel}
        name="client-permission-selection"
      >
        <FormControlLabel className={classes.radioButton} value="none" control={<Radio />} label={bundle.none} />
        <FormControlLabel className={classes.radioButton} value="edit" control={<Radio />} label={bundle.canEdit} />
        <Typography variant="body2" color="textSecondary" className={classes.sectionDescription}>
          {bundle.flagDefinitionEditDialogueCanEditPermNote}
        </Typography>
        <FormControlLabel className={classes.radioButton} value="version" control={<Radio />} label={bundle.releaseVersion} />
        <Typography variant="body2" color="textSecondary" className={classes.sectionDescription}>
          {bundle.flagDefinitionEditDialogueVisibilityReleaseVersionNote}
        </Typography>
        {clientAdminPermission === "version" &&
        <>
          <div className={classes.sectionDescription}>
            <FeatureFlagDefinitionReleaseVersion
              idPrefix="visibility"
              releaseProductType={visibilityReleaseProductType}
              noReleaseProductType={NO_PRODUCT_TYPE}
              releaseProductTypeError={visibilityReleaseProductTypeError}
              noReleaseProductTypeError={NO_ERROR}
              releaseProductVersion={visibilityReleaseProductVersion}
              releaseProductVersionError={visibilityReleaseProductVersionError}
              noReleaseProductVersionError={NO_ERROR}
              setReleaseProductType={setVisibilityReleaseProductType}
              setReleaseProductVersion={setVisibilityReleaseProductVersion}
            />
          </div>
        </>
        }
      </RadioGroup>
    </Fieldset>
  );

  const flagDefinitionLabel = flagDefinition.labels.find((element: any) => element.locale.toLowerCase() === "en_us")?.label;

  const editDialog = isEditDialogMode && (
    <>
      <Typography variant="h4" className={classes.section}> {bundle.globalStatus} </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.inlineNote}>
        {bundle.featureFlagDefEditDialogueDefaultValueNote}
      </Typography>
      <div id="defaultValueControl" data-testid="flag-definition-default-value-control">
        {defaultValueOptions}
      </div>

      <Typography variant="h4" className={classes.section}> {bundle.flagDefinitionEditDialogueOverrideLabel} </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.inlineNote}>
        {bundle.featureFlagDefEditDialogueOverrideNote}
      </Typography>
      <div id="overrideValuesControl" data-testid="flag-definition-override-values-control">
        {overrideValuesOptions}
      </div>
      {clearFlagValues === "enabled" &&
        <Typography data-testid="flag-definition-value-warning" variant="body2" className={classes.sectionWarning}>
          {bundle.featureFlagDefEditDialogueOverrideWarning.replace('{flagName}', flagDefinitionLabel ?? '')}
        </Typography>
      }

      <hr className={classes.section} />
      <div id="visibilityLabel">
        <Typography variant="h4" className={classes.section}> {bundle.globalPermissions} </Typography>
        <Typography variant="body2" color="textSecondary" className={classes.inlineNote}>
          {bundle.flagDefinitionEditDialogueClientPermNote}
        </Typography>
      </div>
      <div id="permissionControl" data-testid="flag-definition-permission-control">
        {clientPermissionOptions}
      </div>

      {possibleValues.type.toLowerCase() === "boolean" &&
      <>
        <hr className={classes.section} />
        <div id="enablementByReleaseVersionControl" data-testid="flag-definition-enablement-by-release-version-control">
          <Typography variant="h4" className={classes.section}>
            {bundle.globalEnablementByReleaseVersion}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.inlineNote}>
            {bundle.flagDefinitionEditDialogueEnablementByReleaseVersionNote}
          </Typography>
          <FeatureFlagDefinitionReleaseVersion
            idPrefix="defaultValue"
            releaseProductType={releaseProductType}
            noReleaseProductType={NO_PRODUCT_TYPE}
            releaseProductTypeError={NO_ERROR}
            noReleaseProductTypeError={NO_ERROR}
            releaseProductVersion={releaseProductVersion}
            releaseProductVersionError={releaseProductVersionError}
            noReleaseProductVersionError={NO_ERROR}
            setReleaseProductType={setReleaseProductType}
            setReleaseProductVersion={setReleaseProductVersion}
          />
        </div>

        <hr className={classes.section} />
        <div id="overrideOptInControl" data-testid="flag-definition-override-opt-in-control">
          <Typography variant="h4" className={classes.section}>
            {bundle.flagDefinitionEditDialogueOverrideOptIn}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.inlineNote}>
            {bundle.flagDefinitionEditDialogueOverrideOptInNote}
          </Typography>
          <RadioGroup
            hasCustomLegend
            className={classes.overrideOptIn}
            onChange={handleOverrideOptIn}
            value={overrideOptIn}
            aria-label={bundle.flagDefinitionEditDialogueOverrideOptInAriaLabel}
            name="override-opt-in"
          >
            <FormControlLabel className={classes.radioButton} value="disabled" control={<Radio />} label={bundle.no} />
            <FormControlLabel className={classes.radioButton} value="enabled" control={<Radio />} label={bundle.yes} />
          </RadioGroup>
          {overrideOptIn === "enabled" &&
          <Typography variant="body2" className={classes.sectionWarning}>
            {bundle.flagDefinitionEditDialogueOverrideOptInWarning}
          </Typography>
          }
        </div>
      </>
      }
    </>
  );

  const confirmDialog = !isEditDialogMode && (
    <div className={classes.section} data-testid="feature-flag-information-confirm-detail-panel">
      <FeatureFlagConfirmDialog
        flagDefinition={flagDefinition}
        defaultValue={flagNewDefaultValue}
        clearFlagValues={clearFlagValues === "enabled"}
        visibility={visibilityPayload}
        releaseVersion={releaseVersionPayload}
        overrideOptIn={overrideOptIn === "enabled"}
      />
      <FeatureFlagCheckBox
        checked={isChecked}
        onChange={handleConfirm}
        label={bundle.featureFlagDefEditDialogueConfirmNote}
      />
    </div>
  );

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      aria-label={bundle.featureFlagDefEditDialogueAriaLabel}
      data-testid="flag-definition-edit-dialog"
    >
      <DialogTitle onClose={handleOnClose} className={classes.dialogTitle} aria-label={bundle.featureFlagDefEditDialogueTitleAriaLabel}>
        {bundle.featureFlagDefEditDialogueTitle.replace('{flagName}', flagDefinitionLabel ?? '')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {editDialog}
        {confirmDialog}
      </DialogContent>

      <DialogActions data-testid="flag-definition-buttons">
        <DefaultButton id="cancel-flag-definition-edit-btn" onClick={handleOnClose}>{bundle.cancel}</DefaultButton>
        {isEditDialogMode &&
          <PrimaryButton id="submit-flag-definition-edit-btn" onClick={handleOnContinue}>{bundle.continue}</PrimaryButton>
        }
        {!isEditDialogMode &&
          <PrimaryButton id="submit-flag-definition-edit-btn" disabled={!isChecked} onClick={handleOnSubmit}>{bundle.save}</PrimaryButton>
        }
      </DialogActions>
    </Dialog>
  );
};
