import { createStyles, makeStyles, TextField, Theme } from "@bb-ui/react-library";
import { MenuItem } from "@bb-ui/react-library/dist/components/MenuItem";
import * as React from "react";
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogTitle: {
    alignItems: 'start',
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  releaseProductTypeInput: {
    marginTop: theme.spacing(1),
  },
  releaseVersionNumberInput: {
    marginTop: theme.spacing(1),
  },
}));

export interface IFeatureFlagDefinitionReleaseVersionProps extends ILocaleProps {
  idPrefix: string;
  releaseProductType: string;
  noReleaseProductType: string;
  releaseProductTypeError: string;
  noReleaseProductTypeError: string;
  releaseProductVersion: string;
  releaseProductVersionError: string;
  noReleaseProductVersionError: string;
  setReleaseProductType: (value: string) => void;
  setReleaseProductVersion: (value: string) => void;
}

export const FeatureFlagDefinitionReleaseVersion: React.FC<IFeatureFlagDefinitionReleaseVersionProps> = (props) => {
  const classes = useStyles(props);
  const {
    idPrefix,
    releaseProductType,
    noReleaseProductType,
    releaseProductTypeError,
    noReleaseProductTypeError,
    releaseProductVersion,
    releaseProductVersionError,
    noReleaseProductVersionError,
    setReleaseProductType,
    setReleaseProductVersion,
    locale,
  } = props;
  const bundle = getBundle(locale);

  function handleReleaseProductTypeChange(event: any) {
    const updatedProductType = event.target.value;
    if (!updatedProductType) {
      // Clicks outside the dropdown will send an undefined value; ignore these
      return;
    }
    setReleaseProductType(updatedProductType);
  }

  function handleReleaseProductVersionChange(event: any) {
    setReleaseProductVersion(event.target.value);
  }

  return (
    <>
      <TextField
        id={`${idPrefix}-release-version-product-type-input`}
        className={classes.releaseProductTypeInput}
        error={releaseProductTypeError !== noReleaseProductTypeError}
        helperText={releaseProductTypeError}
        select
        onClick={handleReleaseProductTypeChange}
        label={bundle.flagDefinitionEditDialogueProductType}
        value={releaseProductType}
        fullWidth
      >
        <MenuItem button key={noReleaseProductType} value={noReleaseProductType}>
          {bundle.flagDefinitionEditDialogueProductTypeNone}
        </MenuItem>
        <MenuItem button key="Learn" value="Learn">
          {bundle.flagDefinitionEditDialogueProductTypeLearn}
        </MenuItem>
        <MenuItem button key="Student" value="Student">
          {bundle.flagDefinitionEditDialogueProductTypeStudent}
        </MenuItem>
        <MenuItem button key="Portal" value="Portal">
          {bundle.flagDefinitionEditDialogueProductTypePortal}
        </MenuItem>
      </TextField>

      {releaseProductType !== noReleaseProductType &&
      <TextField
        id={`${idPrefix}-release-version-number-input`}
        className={classes.releaseVersionNumberInput}
        error={releaseProductVersionError !== noReleaseProductVersionError}
        helperText={releaseProductVersionError}
        onChange={handleReleaseProductVersionChange}
        label={bundle.flagDefinitionEditDialogueProductVersion}
        value={releaseProductVersion}
        placeholder="3900.50.1"
        fullWidth
      />}
    </>
  );
};

export default FeatureFlagDefinitionReleaseVersion;
