import { AxiosResponse } from 'axios';
import {
  IFeatureFlagApiProps,
  FeatureFlagVisibility,
  FeatureFlagReleaseVersion,
} from '../common/common.types';
import { FeatureFlagDefinitionData } from '../feature-flag-definition/use-feature-flag-definitions.types';

export interface IUseTenantFeatureFlagsProps extends IFeatureFlagApiProps {
  /**
     * Tenant unique identifier
     */
  tenantId: string;
}

export interface IFeatureFlagValueData {
  /**
    * Flag key of the fetched flag value
    */
  flagKey: string;

  /**
    * Scope of the fetched flag value
    */
  scope: string;

  /**
    * The value of the requested flag
    */
  value: string;

  /**
    * whether the value is locked.
    */
  locked: boolean;

  /**
    * whether the value has been overridden.
    */
  overridden: boolean;
}

export interface IHashFlagValues {
  [key: string]: IFeatureFlagValueData;
}

export interface IFlagValueChange {
  flagKey: string;
  value?: string;
  locked?: boolean;
}

export interface IFlagDefinitionChange {
  flagKey: string;
  defaultValue?: string;
  visibility?: FeatureFlagVisibility;
}

export interface IFeatureFlagValueUpdateStateHandler {
  updateFeatureFlagValue: (flagKey: string, value?: string, locked?: boolean) => void;
  flagValueChange?: IFlagValueChange;
  flagValueResponse?: AxiosResponse;
  flagValueLoading?: boolean;
  flagValueError?: Error;
}

export interface IFeatureFlagDefinitionUpdateStateHandler {
  updateFeatureFlagDefinition: (flagKey: string, defaultValue?: string, visibility?: FeatureFlagVisibility) => void;
  flagDefinitionChange?: IFlagDefinitionChange;
  flagDefinitionLoading?: boolean;
  flagDefinitionResponse?: AxiosResponse;
  flagDefinitionError?: Error;
}

export interface ITenantFeatureFlagValues {
  flagDefinition: FeatureFlagDefinitionData;
  flagValue?: IFeatureFlagValueData;
  scope: string;
  flagKey: string;
}

export interface IUseTenantFeatureFlagsResults {
  /**
  * Contains all feature flag definitions
  */
  tenantFeatureFlagsData?: ITenantFeatureFlagValues[];
  /**
  * Contains an error if one occurred while fetching a flag definition
  */
  error?: Error;
  /**
  * Indicates whether or not the flag definition is loading
  */
  loading: boolean;
  /**
  * Object to encapsulate the feature flag value update logic
  */
  featureFlagValueUpdateStateHandler: IFeatureFlagValueUpdateStateHandler;
  /**
  * Object to encapsulate the feature flag definition update logic
  */
  featureFlagDefinitionUpdateStateHandler: IFeatureFlagDefinitionUpdateStateHandler;
  /**
  * callback to retrive a tenantFeatureFlag
  */
  getTenantFeatureFlag: (flagKey: string) => ITenantFeatureFlagValues | undefined;
  clearUpdateResponses: () => void;
  clearUpdateErrors: () => void;
}

export class TenantFeatureFlagsUtils {
  /**
   * This methods help us to figure out if a given feature flag is visible by the given tenantId and region.
   * There are some specific cases that are worth to mention:
   *  - A feature flag is visible if its region is present on the region criteria even if its tenant id is not
   *    included in tenant criteria.
   *  - A feature flag is visible if its fleet is present on the fleet criteria even if its tenant id is not
   *    included in tenant criteria.
   *  - Visibility is not modifiable if isVisible = true for all tenants(there is no criteria at all).
   *  - Visibility is not modifiable if isVisible = true because of the region criteria.
   *  - Visibility is not modifiable if isVisible = true because of the fleet criteria.
   *  - Visibility is modifiable in all other cases.
   */
  public static getVisibility(flagVisibility: FeatureFlagVisibility, tenandId: string, region: string, fleetId?: string, releaseVersion?: FeatureFlagReleaseVersion) {
    let isVisible = flagVisibility.visible;
    let visibilityModifiable = !isVisible;

    const regionsCriteria = flagVisibility.criteria?.regions;
    const tenantsCriteria = flagVisibility.criteria?.tenants;
    const fleetsCriteria = flagVisibility.criteria?.fleets;
    const releaseVersionCriteria = flagVisibility.criteria?.releaseVersion;

    if ((regionsCriteria && regionsCriteria.includes(region)) ||
        (fleetId && fleetsCriteria && fleetsCriteria.includes(fleetId)) ||
        (releaseVersion && releaseVersionCriteria &&
            releaseVersion.productType === releaseVersionCriteria.productType &&
            releaseVersion.version.major === releaseVersionCriteria.version.major &&
            releaseVersion.version.minor === releaseVersionCriteria.version.minor &&
            releaseVersion.version.patch === releaseVersionCriteria.version.patch)) {
      isVisible = true;
      visibilityModifiable = false;
    } else if (tenantsCriteria && tenantsCriteria.includes(tenandId)) {
      isVisible = true;
      visibilityModifiable = true;
    }
    return { isVisible, visibilityModifiable };
  }

  public static getNewTenantVisibility(flagVisibility: FeatureFlagVisibility, tenandId: string, isVisible: boolean) {
    let tenants = flagVisibility?.criteria?.tenants ?? [];
    const regions = flagVisibility?.criteria?.regions ?? [];
    const fleets = flagVisibility?.criteria?.fleets ?? [];
    const releaseVersion = flagVisibility?.criteria?.releaseVersion;
    if (isVisible) {
      tenants.push(tenandId);
    } else {
      tenants = tenants.filter((ele) => ele !== tenandId);
    }
    const newVisibility: FeatureFlagVisibility = {
      visible: flagVisibility.visible,
      criteria: {
        ...(tenants.length && { tenants }),
        ...(regions.length && { regions }),
        ...(fleets.length && { fleets }),
        ...(releaseVersion && { releaseVersion }),
      },
    };
    return newVisibility;
  }
}
